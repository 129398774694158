import React from 'react'
import {Link} from 'gatsby'

const PageItem = ({styles, item}) => {
	return (
		<div className={styles.item} key={item.id}>
			<img src={item.feature_image.childImageSharp.fluid.src} alt={`sks - blog`}/>
			<h3>
				<Link to={`/blog/${item.slug}`}>{item.name}</Link>
			</h3>
			<p>{item.excerpt}</p>
			<div className={styles.tagdateWrapper}>
				<span>{new Date(item.created_at).toLocaleString()} | Smart key</span>
			</div>
		</div>
	)
}
export default PageItem