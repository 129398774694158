import React from 'react' 
import { graphql, useStaticQuery} from "gatsby"

import PageItem from './post-item'
import styles from './listing.module.css'

const RA = ({categories})=>{

	console.log(categories);

	const query = useStaticQuery(graphql`
		query {
			allStrapiBlogs(sort: {fields: created_at, order: DESC}) {
			    edges {
			      node {
				        id
				        name
				        slug
				        excerpt
				        content
				        created_at
				        feature_image {
				          childImageSharp {
				            fluid {
				              src
				              originalName
				            }
				          }
				        }
				        blog_categories {
				          slug
				        }
			      }
			    }
		  	}
		}
	`)

	let blogs1 = [];
	query.allStrapiBlogs.edges.forEach(({node})=>{
		node.blog_categories.forEach((item) => {
			let index = categories.findIndex(i=>i.slug === item.slug);
			if(index > -1 && blogs1.length <= 3)
			blogs1.push(node);
		})
		
	})

	console.log(blogs1)

	return(
		<>
		<div className={styles.itemsWrapper}>
		     {
		      	blogs1.map((blog)=>(
		      			<PageItem key={blog.id} styles={styles} item={blog}/>
		      		)
		      	)
		    }
		</div>
		</>
	)
}

export default RA