import React from 'react'
import Markdown from 'react-markdown'

import Layout from "../components/layout"
import SEO from "../components/seo";
import DetailsHead from "../components/detailshead"
import styles from '../components/blogs/single.module.css'
import RelatedBlog from '../components/blogs/related-articles'
import PrevNext from '../components/commons/previous-next'

const Post = ({data}) => {
	let d = data.strapiBlogs;
	return(
		<Layout>
    	<SEO title={`Posts | Car LockSmith Service Provider`}/>
    	<DetailsHead title="Blog"/>
	    <div className={styles.container}>
			<div className={styles.wrapper}>
				<h1 className={styles.blogTitle}>{d.name}</h1>
				<div className={styles.blogCategory}>{d.blog_categories.map((cat) => { return( cat.slug ) })}</div>
				<div className={styles.blogImgContainer}>
					<img src={d.feature_image.publicURL} />
				</div>
				<div className={styles.markdown}>
					<Markdown source={d.content} escapeHtml={true}/>
				</div>
				<div className={styles.blogDate}>{new Date(d.created_at).toLocaleString()} | Smart key services</div>
			 </div>
		</div>

		<div className={styles.containerRelated}>
			<div className={styles.wrapper}>
				<PrevNext styles={styles} slug={d.slug}/>
				<h3 className={styles.headRelated}>Related articles</h3>
				<RelatedBlog categories={d.blog_categories}/>
			</div>
		</div>
    	</Layout>
    )
}

export default Post

export const query = graphql`
  query Post($slug: String!) {
  strapiBlogs(slug: { eq: $slug }){
    id
    name
    slug
    excerpt
    content
    created_at
    feature_image{
      publicURL
    }
    blog_categories{
      slug
    }
  }
  }
` 