import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

const PrevNext = ({styles, slug}) => {
	const query = useStaticQuery(graphql`
	query {
		 allStrapiBlogs {
	     edges {
	       node {
	         id
	         slug
	       }
	       previous {
	         slug
	       }
	       next {
	         slug
	       }
	     }
	   }
	  }
	`)
	let p = [];
	query.allStrapiBlogs.edges.forEach((item)=>{
		p.push(item)
	})
	p = p;
	let currentData = p.find((item)=>{
		return item.node.slug === slug
	})
	return(
		<div className={styles.blogPagination}>
			{
				currentData.previous &&
				<Link to={`/blog/${currentData.previous.slug}`}>‹ Previous</Link>
			}
			{
				currentData.next &&
				<Link to={`/blog/${currentData.next.slug}`}>Next ›</Link>
			}
		</div>
	)
}

export default PrevNext